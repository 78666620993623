"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoRecoveryService = void 0;
const Constants = __importStar(require("../constants"));
const bff_service_1 = require("../bff-service");
const applicationDataService_1 = require("../applicationDataService");
const auth_service_1 = require("../auth-service");
class AutoRecoveryService {
    constructor() {
        this.clientApiUrl = '';
        this.clientApplicationCode = '';
    }
    /**
    * registers the service worker and listen for the messages
    */
    register(autoRecoveryWorker) {
        // Add event listener for the 'install' event
        autoRecoveryWorker.addEventListener(Constants.INSTALL_EVENT, (event) => {
            this.handleInstallEvent(autoRecoveryWorker);
        });
        // Add event listener for the 'activate' event
        autoRecoveryWorker.addEventListener(Constants.ACTIVATE_EVENT, (event) => {
            this.handleActivateEvent(event, autoRecoveryWorker);
        });
        // Add event listener for the 'message' event
        autoRecoveryWorker.addEventListener(Constants.MESSAGE_EVENT, (event) => {
            this.handleMessageEvent(event);
        });
    }
    handleInstallEvent(autoRecoveryWorker) {
        autoRecoveryWorker.skipWaiting();
        console.log('Auto Recovery Worker: Installed');
    }
    handleActivateEvent(event, autoRecoveryWorker) {
        autoRecoveryWorker.clients.claim().then(() => {
            console.log('Auto Recovery Worker: Activated');
        }).catch(() => {
            console.error('Auto Recovery Worker: Activation failed');
        });
    }
    handleMessageEvent(event) {
        // Check if the message is for auto recovery registration
        if (event.data && this.isAutoRecoveryRegistration(event.data)) {
            console.log('Auto Recovery Registration');
            const autoRecoveryRegistration = event.data;
            this.clientApiUrl = autoRecoveryRegistration.apiUrl;
            this.clientApplicationCode = autoRecoveryRegistration.applicationCode;
            const timeInterval = autoRecoveryRegistration.timeInterval === undefined ? Constants.TIMEINTERVAL_5_MINS : autoRecoveryRegistration.timeInterval;
            setInterval(() => {
                var _a;
                const data = Constants.KEEP_ALIVE_MESSAGE;
                (_a = event.source) === null || _a === void 0 ? void 0 : _a.postMessage(data);
            }, Constants.TIMEINTERVAL_25_SECONDS);
            setInterval(() => {
                var _a;
                const data = Constants.FETCH_NEW_DATA;
                (_a = event.source) === null || _a === void 0 ? void 0 : _a.postMessage(data);
            }, timeInterval);
        }
        else if (event.data && this.isAutoRecoverySaveData(event.data)) {
            const tempData = event.data;
            if (tempData.objectId === undefined) {
                tempData.objectId = '';
            }
            if (tempData.temporaryData && this.clientApiUrl && this.clientApplicationCode) {
                this.saveData(tempData.objectType, tempData.temporaryData, this.clientApiUrl, this.clientApplicationCode, tempData.objectId);
            }
        }
        else {
            console.log('Invalid data');
        }
    }
    /**
    * Save the temporary data by making call to the AutoRecovery API
    * @param objectType - The object type
    * @param temporaryData - The temporary data to be saved
    * @param apiUrl - The API URL
    * @param appCode - The application code
    */
    saveData(objectType, temporaryData, apiUrl, appCode, objectId = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const url = (apiUrl === undefined ? '' : apiUrl) + Constants.AUTO_RECOVERY_ENDPOINT.SAVE_DATA(objectType, objectId);
            const jsonTempData = {
                temporaryData: temporaryData,
            };
            // call fetchThroughBff but pass the application code in the header and verify
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "post",
                body: JSON.stringify(jsonTempData),
                headers: this.getRequestHeaders(appCode),
            });
            res = yield this._handleErrors(res);
        });
    }
    /**
    * Get all Auto Recovery data by the object type
    * @param objectType - The object type
    */
    getAllAutoRecoveryData(objectType) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + Constants.AUTO_RECOVERY_ENDPOINT.GET_ALL(objectType);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            const autoRecoveryDataList = yield res.json();
            if (autoRecoveryDataList === null || autoRecoveryDataList === undefined || autoRecoveryDataList.autoRecoveryDataDto === undefined) {
                return null;
            }
            const autoRecoveryDataResponse = autoRecoveryDataList.autoRecoveryDataDto.map((data) => {
                return {
                    ObjectType: data.objectType,
                    ObjectId: data.objectId,
                    TemporaryData: data.temporaryData,
                    TimeStamp: data.timeStamp,
                };
            });
            return autoRecoveryDataResponse;
        });
    }
    /**
   * Get Auto Recovery data by the object type and object id
   * @param objectType - The object type
   * @param objectId - The object id
   */
    getAutoRecoveryData(objectType, objectId = '') {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + Constants.AUTO_RECOVERY_ENDPOINT.GET(objectType, objectId);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            const autoRecoveryData = yield res.json();
            if (autoRecoveryData === null || autoRecoveryData === undefined || autoRecoveryData.autoRecoveryDataDto === undefined) {
                return null;
            }
            const autoRecoveryDataResponse = {
                objectType: autoRecoveryData.autoRecoveryDataDto.objectType,
                objectId: autoRecoveryData.autoRecoveryDataDto.objectId,
                temporaryData: autoRecoveryData.autoRecoveryDataDto.temporaryData,
                timeStamp: autoRecoveryData.autoRecoveryDataDto.timeStamp,
            };
            return autoRecoveryDataResponse;
        });
    }
    deleteAutoRecoveryData(objectType, objectId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (objectId === undefined) {
                objectId = '';
            }
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + Constants.AUTO_RECOVERY_ENDPOINT.DELETE(objectType, objectId);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "delete",
                headers: this.getRequestHeaders(),
            });
            res = yield this._handleErrors(res);
            return res;
        });
    }
    /**
    * Handles errors for API calls.
    * @param {Response} response - The response object from the API call.
    * @returns {Promise<any>} - Returns the response object if it is ok.
    */
    _handleErrors(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!response.ok) {
                console.log('Error during API calls', response.status);
            }
            return response;
        });
    }
    /**
    * Check if the data is for Auto Recovery registration
    * @param obj - The object to be checked
    */
    isAutoRecoveryRegistration(obj) {
        return obj.action === Constants.AutoRecoveryAction.Register;
    }
    /**
    * Check if the data is for Auto Recovery save
    * @param obj - The object to be checked
    */
    isAutoRecoverySaveData(obj) {
        return obj.action === Constants.AutoRecoveryAction.Save;
    }
    /**
    * Get the request headers
    * @param appCode - The application code
     */
    getRequestHeaders(appCode) {
        var _a;
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "applicationCode": appCode ? appCode : (_a = auth_service_1.AuthService.Instance) === null || _a === void 0 ? void 0 : _a.getApplicationCode(),
        };
    }
}
exports.AutoRecoveryService = AutoRecoveryService;
