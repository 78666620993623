"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileUtility = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const applicationDataService_1 = require("../applicationDataService");
const user_profile_service_1 = require("../user-profile-service");
const date_fns_tz_1 = require("date-fns-tz");
const model_1 = require("../model");
const constants_1 = require("../constants");
/**
 * Utility class for managing user profile data.
 */
class UserProfileUtility {
    constructor(profileSettings) {
        this._userProfileData = {};
        this._user = {
            firstName: "",
            lastName: "",
            nickName: "",
            email: "",
            phone: "",
            idp: "",
            language: "",
        };
        // default values are as per new user created settings from backend
        this._profileSettings = {
            profilePictureType: "",
            browserDefaultLocale: "",
            dateDisplay: "dd-MM-yyyy",
            hourNotation12: true,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            listSeparator: ",",
            decimalSeparator: {
                Separator: ".",
                Locale_number: "en-US",
                UseGroup: "false",
            },
        };
        if ((profileSettings === null || profileSettings === void 0 ? void 0 : profileSettings.length) > 0) {
            this._formatAndSetUserProfileData(profileSettings);
        }
    }
    static getInstance() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!UserProfileUtility._instance) {
                try {
                    const userId = applicationDataService_1.ApplicationDataService.Instance.getUserDetails().userId ||
                        applicationDataService_1.ApplicationDataService.Instance.getUserId();
                    if (userId) {
                        const response = yield user_profile_service_1.UserProfileService.Instance.getUserProfileData(userId);
                        UserProfileUtility._instance = new UserProfileUtility(response === null || response === void 0 ? void 0 : response.profileSettings);
                    }
                    else {
                        UserProfileUtility._instance = new UserProfileUtility([]);
                    }
                }
                catch (error) {
                    console.error("Error getting user profile data", error);
                }
            }
            return UserProfileUtility._instance;
        });
    }
    _formatAndSetUserProfileData(profileSettings) {
        // format and set user profile data
        this._userProfileData = profileSettings === null || profileSettings === void 0 ? void 0 : profileSettings.reduce((dictionary, settings) => {
            dictionary[settings.key] = settings;
            return dictionary;
        }, {});
        // set user data
        this._setUserData();
        // set profile settings data
        this._setProfileSettingsData();
    }
    _setUserData() {
        var _a, _b, _c, _d, _e, _f, _g;
        this._user = {
            firstName: (_a = this._userProfileData[model_1.AccountSettingsKey.PROFILE_FIRSTNAME]) === null || _a === void 0 ? void 0 : _a.value,
            lastName: (_b = this._userProfileData[model_1.AccountSettingsKey.PROFILE_LASTNAME]) === null || _b === void 0 ? void 0 : _b.value,
            nickName: (_c = this._userProfileData[model_1.AccountSettingsKey.PROFILE_NICKNAME]) === null || _c === void 0 ? void 0 : _c.value,
            email: (_d = this._userProfileData[model_1.AccountSettingsKey.PERSONAL_INFORMATION_EMAIL]) === null || _d === void 0 ? void 0 : _d.value,
            phone: (_e = this._userProfileData[model_1.AccountSettingsKey.PERSONAL_INFORMATION_PHONE]) === null || _e === void 0 ? void 0 : _e.value,
            idp: (_f = this._userProfileData[model_1.AccountSettingsKey.LOGGEDIN_IDP]) === null || _f === void 0 ? void 0 : _f.value,
            language: (_g = this._userProfileData[model_1.AccountSettingsKey.PERSONAL_INFORMATION_LANGUAGE]) === null || _g === void 0 ? void 0 : _g.value,
        };
    }
    _setProfileSettingsData() {
        var _a, _b, _c, _d, _e, _f, _g;
        this._profileSettings = {
            profilePictureType: (_a = this._userProfileData[model_1.AccountSettingsKey.PROFILE_PICTURE_TYPE]) === null || _a === void 0 ? void 0 : _a.value,
            browserDefaultLocale: (_b = this._userProfileData[model_1.AccountSettingsKey.BROWSER_DEFAULTS_LOCALE]) === null || _b === void 0 ? void 0 : _b.value,
            dateDisplay: (_c = this._userProfileData[model_1.AccountSettingsKey.DATETIME_DATE_DISPLAY]) === null || _c === void 0 ? void 0 : _c.value,
            hourNotation12: ((_d = this._userProfileData[model_1.AccountSettingsKey.DATETIME_HOUR_NOTATION]) === null || _d === void 0 ? void 0 : _d.value) === constants_1.HOUR12,
            timeZone: (_f = (_e = this._userProfileData[model_1.AccountSettingsKey.DATETIME_TIME_ZONE]) === null || _e === void 0 ? void 0 : _e.metaData) === null || _f === void 0 ? void 0 : _f.IANATimeZone,
            listSeparator: (_g = this._userProfileData[model_1.AccountSettingsKey.FORMATTING_LIST_SEPARATOR]) === null || _g === void 0 ? void 0 : _g.value,
            decimalSeparator: this._getDecimalSeparator(),
        };
    }
    _getDecimalSeparator() {
        var _a, _b;
        const metaData = (_a = this._userProfileData[model_1.AccountSettingsKey.FORMATTING_DECIMAL_SEPARATOR]) === null || _a === void 0 ? void 0 : _a.metaData;
        return {
            Separator: (_b = this._userProfileData[model_1.AccountSettingsKey.FORMATTING_DECIMAL_SEPARATOR]) === null || _b === void 0 ? void 0 : _b.value,
            Locale_number: metaData === null || metaData === void 0 ? void 0 : metaData.Locale_number,
            UseGroup: metaData === null || metaData === void 0 ? void 0 : metaData.UseGroup,
        };
    }
    get firstName() {
        return this._user.firstName;
    }
    get lastName() {
        return this._user.lastName;
    }
    get nickName() {
        return this._user.nickName;
    }
    get email() {
        return this._user.email;
    }
    get phoneNumber() {
        return this._user.phone;
    }
    get languageTag() {
        var _a;
        return (_a = this._userProfileData[model_1.AccountSettingsKey.PERSONAL_INFORMATION_LANGUAGE]) === null || _a === void 0 ? void 0 : _a.metaData['Language_tag'];
    }
    get dateDisplayPreference() {
        return this._profileSettings.dateDisplay;
    }
    get hourNotation12() {
        return this._profileSettings.hourNotation12;
    }
    get timeZone() {
        return this._profileSettings.timeZone;
    }
    get listSeparator() {
        return this._profileSettings.listSeparator;
    }
    get decimalSeparator() {
        return this._profileSettings.decimalSeparator;
    }
    getProfilePicture() {
        var _a;
        return {
            type: this._profileSettings.profilePictureType,
            image: this._profileSettings.profilePictureType === "Initials"
                ? null
                : (_a = this._userProfileData[model_1.AccountSettingsKey.PROFILE_PICTURE_IMAGE]) === null || _a === void 0 ? void 0 : _a.value,
        };
    }
    // returns the colors for profile picture's initials
    getInitialsColors() {
        var _a, _b;
        return {
            foreground: (_a = this._userProfileData[model_1.AccountSettingsKey.PROFILE_PICTURE_FOREGROUND_COLOR]) === null || _a === void 0 ? void 0 : _a.value,
            background: (_b = this._userProfileData[model_1.AccountSettingsKey.PROFILE_PICTURE_BACKGROUND_COLOR]) === null || _b === void 0 ? void 0 : _b.value,
        };
    }
    formatDate(date) {
        try {
            return (0, date_fns_tz_1.formatInTimeZone)(date, this._profileSettings.timeZone, this._profileSettings.dateDisplay);
        }
        catch (e) {
            console.error("Error formatting date", e);
            return date.toString();
        }
    }
    formatTime(date) {
        try {
            const timePattern = this._profileSettings.hourNotation12
                ? model_1.HourNotation[constants_1.HOUR12]
                : model_1.HourNotation[constants_1.HOUR24];
            return (0, date_fns_tz_1.formatInTimeZone)(date, this._profileSettings.timeZone, timePattern);
        }
        catch (e) {
            console.error("Error formatting time", e);
            return date.toString();
        }
    }
    formatDateTime(date) {
        try {
            const pattern = `${this._profileSettings.dateDisplay}, ${this._profileSettings.hourNotation12
                ? model_1.HourNotation[constants_1.HOUR12]
                : model_1.HourNotation[constants_1.HOUR24]}`;
            return (0, date_fns_tz_1.formatInTimeZone)(date, this._profileSettings.timeZone, pattern);
        }
        catch (e) {
            console.error("Error formatting date time", e);
            return date.toString();
        }
    }
    formatNumber(number) {
        return new Intl.NumberFormat(this._profileSettings.decimalSeparator.Locale_number, {
            useGrouping: this._profileSettings.decimalSeparator.UseGroup === "true",
        }).format(number);
    }
    /**
     * Gets the current date and time object formatted according to the user's profile time zone setting.
     *
     * This method creates a new Date object representing the current date and time, formats it according
     * to the specified time zone in the user's profile settings, and then returns a new Date object
     * based on this formatted string.
     *
     * @returns {Date} A Date object representing the current date and time formatted according to the
     * user's profile time zone setting.
     */
    getCurrentDateObjBasedOnUserProfile() {
        try {
            const date = new Date();
            const newDate = (0, date_fns_tz_1.formatInTimeZone)(date, this._profileSettings.timeZone, "yyyy-MM-dd HH:mm:ss");
            return new Date(newDate);
        }
        catch (error) {
            console.error("Error formatting date based on time zone", error);
            return new Date();
        }
    }
    /**
     * Gets the date object formatted according to the specified time zone.
     *
     * @param date The date object to format in local time.
     * @param timeZone The time zone to format the date in.
     * @returns {Date} A Date object representing the formatted date in the specified time zone.
     */
    getDateBasedOnTimeZone(date, timeZone) {
        try {
            const formattedDate = (0, date_fns_tz_1.formatInTimeZone)(date, timeZone, "yyyy-MM-dd HH:mm:ss");
            return new Date(formattedDate);
        }
        catch (e) {
            console.error("Error formatting date based on time zone", e);
            return date;
        }
    }
    /**
     * Gets the date object formatted according to UTC.
     *
     * @param userProfileDate The date object to format in profile date time zone.
     * @param timeZone The time zone of the userProfileDate in.
     * @returns {Date} A Date object representing the formatted date in UTC.
     */
    getUserProfileDateToUTC(userProfileDateObj, timeZone) {
        try {
            const newDate = (0, date_fns_tz_1.zonedTimeToUtc)(userProfileDateObj, timeZone);
            return newDate;
        }
        catch (e) {
            console.log("Error in converting user profile date to UTC date", e);
            return userProfileDateObj;
        }
    }
}
exports.UserProfileUtility = UserProfileUtility;
